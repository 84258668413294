import { getRefreshToken } from "utils/tokenService";
import { apiAxios } from "../../api";

interface ILogin {
  email: string;
  password: string;
}
export const loginApi = (body: ILogin) => {
  return apiAxios.post("auth/admin/signin", body);
};

export const getNewTokens = async (): Promise<{
  accessToken: string;
  refreshToken: string;
}> => {
  const refreshToken = getRefreshToken();

  const { data } = await apiAxios.post(
    "auth/refresh",
    { token: refreshToken },
    {
      _retry: true,
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }
  );

  return data;
};

export const fetchUserInfo = () => apiAxios.get("admin/info");
