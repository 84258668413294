export const setTokens = async ({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken: string;
}) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

export const getAccessToken = (): string | null => {
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken) return null;

  return accessToken;
};

export const getRefreshToken = (): string | null => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) return null;

  return refreshToken;
};

export const clearTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};
