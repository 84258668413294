import { apiAxios } from "api";
import { AxiosResponse } from "axios";
import { ServerResponse } from "types";
import { Chat, ChatListItem, ChatMessageItem } from "types/chat";
import { CHATS_LOADING_LIMIT, MESSAGES_LOADING_LIMIT } from "utils/constants";

interface fetchBookingChatResponse extends ServerResponse {
  chat: Chat | null;
  totalMessages: number;
}

interface fetchSuportChatsListResponse extends ServerResponse {
  chats: ChatListItem[];
  totalChats: number;
  allUnreadMessages: number;
}

interface fetchSuportChatByIdResponse extends ServerResponse {
  chat: Chat;
  totalMessages: number;
}

interface updateChatStatusResponse extends ServerResponse {
  chat: ChatListItem;
}

export const fetchBookingChat = (
  orderId: number | string,
  page: number,
  limit = 20
): Promise<AxiosResponse<fetchBookingChatResponse>> => {
  return apiAxios.get(
    `/chat/rideChat?orderId=${orderId}&limit=${limit}&page=${page}`
  );
};

export const fetchSuportChatsList = (
  type: "ongoing" | "completed" | "all",
  page: number,
  limit = CHATS_LOADING_LIMIT
): Promise<AxiosResponse<fetchSuportChatsListResponse>> => {
  return apiAxios.get(`/chat/support/list/${type}?page=${page}&limit=${limit}`);
};

export const fetchSuportChatById = (
  chatId: number,
  page: number,
  limit = MESSAGES_LOADING_LIMIT
): Promise<AxiosResponse<fetchSuportChatByIdResponse>> => {
  return apiAxios.get(
    `/chat/support/?chatId=${chatId}&page=${page}&limit=${limit}`
  );
};

export const updateChatStatus = (
  chatId: number,
  newStatus: "ongoing" | "completed"
): Promise<AxiosResponse<updateChatStatusResponse>> => {
  return apiAxios.patch(`/chat/change/${newStatus}?chatId=${chatId}`);
};
