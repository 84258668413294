import axios, { AxiosError } from "axios";
import {
  clearTokens,
  getAccessToken,
  getRefreshToken,
} from "./../utils/tokenService";
import { CustomAxiosRequestConfig } from "./axios.types";
import { STATUS_CODES } from "./statusCodes";
import { getNewTokens } from "./login";
import { TokensService } from "utils";

declare module "axios" {
  export interface AxiosRequestConfig {
    _retry?: boolean;
  }
}

export const apiAxios = axios.create({
  headers: {
    "content-type": "application/json",
  },
  baseURL: "https://api.preevo.co.uk/",
});

apiAxios.interceptors.request.use(
  async (config: CustomAxiosRequestConfig) => {
    //token
    const token = getAccessToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    //abort with SetTimeout 10sec
    const abortController = new AbortController();
    const timeout = 10000;

    setTimeout(() => {
      abortController.abort();
    }, timeout);

    config.timeout = timeout;

    config.cancelToken = new axios.CancelToken((cancel) => {
      abortController.signal.addEventListener("abort", () => {
        cancel("Request cancelled due to timeout");
      });
    });

    return config;
  },
  async (error: AxiosError) => {
    return await Promise.reject(error);
  }
);

apiAxios.interceptors.response.use(
  (response) => {
    clearTimeout(response.config.timeout);
    return response;
  },
  (error) => {
    if (error.code === "ABORTED") {
      alert("Request timed out");
      throw new Error("Request timed out");
    }
    throw error;
  }
);

// Add response interceptor
apiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config as CustomAxiosRequestConfig;
    const refreshToken = getRefreshToken();

    if (
      error.response &&
      (error.response.status === STATUS_CODES.UNAUTHORIZED ||
        error.response.status === STATUS_CODES.FORBIDDEN) &&
      !originalRequest?._retry &&
      refreshToken
    ) {
      console.log("TOKEN EXPIRED");
      if (!originalRequest) {
        return await Promise.reject(error);
      }

      originalRequest._retry = true;

      try {
        const { accessToken, refreshToken } = await getNewTokens();

        TokensService.setTokens({ accessToken, refreshToken });

        console.log("TOKEN REFRESHED!");

        return await apiAxios(originalRequest);
      } catch (refreshError) {
        clearTokens();
        window.location.assign("/login");

        return await Promise.reject(refreshError);
      }
    }

    return await Promise.reject(error);
  }
);
